import React from "react"
import { Helmet } from "react-helmet"

export default () => (
  <Helmet>
    <script
      async
      src="https://epnt.ebay.com/static/epn-smart-tools.js"
    ></script>
  </Helmet>
)
